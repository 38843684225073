import { CSSObject } from '@emotion/core';
import cn from 'classnames';
import { FC, HTMLProps, ReactNode, useEffect, useRef } from 'react';
import Modal, { Props as ReactModalProps } from 'react-modal';
import { useSwipeable } from 'react-swipeable';
import { useHeaderStore } from 'src/store';

import { useBodyScrollLock } from '@context/bodyScrollLock';

import { scale, typography } from '@scripts/gds';
import { useMedia } from '@scripts/hooks';

import CrossIcon from '@icons/24/cross.svg';

import { Body, BodyProps } from './Body';
import { Footer, FooterProps } from './Footer';

export interface PopupProps extends ReactModalProps {
    /** Popup content */
    children: ReactNode | ReactNode[];
    /** Add close button */
    isCloseButton?: boolean;
    /** close popup */
    onRequestClose?: () => void;
    /** Fullscreen mode */
    isFullscreen?: boolean;
    /** Title text */
    title?: ReactNode;
    /** Unique name for headings association. Set id yourself for multiple popups differing */
    id?: string;
    /** Enable  or disable scroll inside, for use ID prop required */
    scrollInside?: boolean;
    /** Сss for children wrapper in popup */
    popupCss?: CSSObject;
    /** Class name */
    className?: string;
    /** Does popup appear from right */
    rightHanded?: boolean;
    /** Does popup appear from left */
    leftHanded?: boolean;
    /** Styles for catalog */
    isCatalog?: boolean;
    /** Styles for cart */
    isCart?: boolean;
    /** Styles for auth */
    isAuth?: boolean;
    /** Styles for refund */
    isNewStylePopupmobile?: boolean;
    /** Styles for refund */
    isRefund?: boolean;
    /** Styles for delivery */
    isDelivery?: boolean;
    /** Scroll for overlay */
    isScrollOverlay?: boolean;
    /** Component with the biggest zIndex */
    isHighLevel?: boolean;
    /** Width&Height of Close-Button */
    closebuttonsize?: number;
    /** Styles for auth modal wrapper */
    borderWpaper?: string;
    /** fit content for mobile */
    fitContent?: boolean;
    /** new style */
    isNewPopup?: boolean;
    /** Style for zero stock popup(custom border-radius) */
    isZeroStock?: boolean;
}

const CloseButton: FC<HTMLProps<HTMLButtonElement> & { closebuttonsize: number; isnewauth: boolean }> = props => {
    const { closebuttonsize, isnewauth, ...rest } = props || {};

    const { sm } = useMedia();

    return (
        <button
            aria-label="Закрыть попап"
            css={{
                position: 'absolute',
                top: 0,
                right: 0,
                width: scale(closebuttonsize),
                height: scale(closebuttonsize),
                zIndex: 2,
                transition: 'opacity ease-in 300ms',
                ':hover': { opacity: 0.8 },
                ...(isnewauth && {
                    [sm]: {
                        display: 'none',
                    },
                }),
            }}
            {...rest}
            type="button"
        >
            <CrossIcon />
        </button>
    );
};

const Popup: FC<PopupProps> & { Body: FC<BodyProps>; Footer: FC<FooterProps> } = ({
    className,
    popupCss,
    children,
    onRequestClose,
    closeTimeoutMS = 300,
    isCloseButton = true,
    isFullscreen = false,
    isZeroStock = false,
    isCatalog = false,
    isCart = false,
    isAuth = false,
    isNewStylePopupmobile = false,
    isRefund = false,
    isDelivery = false,
    isScrollOverlay = false,
    title,
    scrollInside = false,
    isHighLevel = false,
    rightHanded,
    leftHanded,
    onAfterOpen,
    id = 'popup',
    isOpen,
    borderWpaper,
    fitContent,
    closebuttonsize = 9,
    isNewPopup,
    ...props
}) => {
    if (rightHanded && leftHanded) {
        console.error(`You must use either rightHanded or leftHanded prop, not both at the same time`);
    }

    if (popupCss) {
        console.warn(`Use css prop on Popup component. popupCss prop will be removed`);
    }

    const { disableScroll, enableScroll } = useBodyScrollLock();
    const { sm, xs } = useMedia();
    const baseContentClass = cn('popup-content', {
        leftHanded,
        rightHanded,
        isFullscreen,
        isCatalog,
        isCart,
        isRefund,
        isDelivery,
        scrollInside: scrollInside || rightHanded || leftHanded,
        isZeroStock,
        isAuth,
    });

    const isFixed = useHeaderStore(({ isFixed }) => isFixed);

    let overlayClass = cn('popup-overlay', { isFullscreen, isCart, leftHanded, isScrollOverlay, isAuth, isZeroStock });

    if (isCatalog) {
        overlayClass = cn('popup-overlay popup-overlay--offset', {
            isFullscreen,
            isCatalog,
            isCart,
            leftHanded,
            isScrollOverlay,
        });

        if (isFixed) {
            overlayClass = cn('popup-overlay popup-overlay--offset-custom', {
                isFullscreen,
                isCatalog,
                isCart,
                leftHanded,
                isScrollOverlay,
            });
        }
    }

    if (isHighLevel) {
        overlayClass = cn(`${overlayClass} popup-overlay--index`);
    }

    const contentRef = useRef<HTMLElement | null>(null);

    const handlersBox = useSwipeable({
        onSwiped: ({ dir, event }) => {
            event.stopPropagation();

            if (dir === 'Down') {
                onRequestClose && onRequestClose();
            }
        },
        //@ts-ignore lib
        preventDefaultTouchmoveEvent: true,
    });

    const { ref: documentRef } = useSwipeable({
        //@ts-ignore lib
        preventDefaultTouchmoveEvent: true,
    });
    // attach swipeable to document
    useEffect(() => {
        //@ts-ignore lib
        documentRef(document);
        //@ts-ignore lib
        return () => documentRef({});
    });

    /** Enable body scroll when close popup */
    useEffect(() => {
        if (!isOpen) enableScroll();
    }, [enableScroll, isOpen]);

    return (
        <Modal
            bodyOpenClassName={null}
            contentRef={node => {
                contentRef.current = node;
            }}
            overlayClassName={{
                base: overlayClass,
                beforeClose: 'popup-overlay--before-close',
                afterOpen: 'popup-overlay--after-open',
            }}
            className={{
                base: cn(baseContentClass, borderWpaper),
                beforeClose: 'popup-content--before-close',
                afterOpen: 'popup-content--after-open',
            }}
            aria={{ labelledby: id }}
            closeTimeoutMS={closeTimeoutMS}
            {...(process.env.IS_STORYBOOK && { ariaHideApp: false })}
            {...props}
            onRequestClose={onRequestClose}
            onAfterOpen={e => {
                if (onAfterOpen) onAfterOpen(e);
                disableScroll();
            }}
            isOpen={isOpen}
        >
            <div
                css={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    ...((rightHanded || leftHanded) && { width: scale(42) }),
                    [sm]: {
                        maxWidth: 'initial',
                        width: '100%',
                        height: '100%',
                    },
                    [xs]: {
                        width: fitContent ? '100vh' : '100%',
                        height: fitContent ? '100vh' : '100%',
                    },
                    ...(!isFullscreen &&
                        !isCatalog &&
                        !rightHanded &&
                        !leftHanded && { borderRadius: `${scale(1, true)}px`, maxWidth: scale(72) }),
                    ...popupCss,
                }}
                className={className}
            >
                {isCloseButton && (
                    <CloseButton
                        closebuttonsize={closebuttonsize}
                        onClick={onRequestClose}
                        isnewauth={isAuth || isNewStylePopupmobile}
                    />
                )}
                {isNewStylePopupmobile && (
                    <div
                        {...handlersBox}
                        css={{
                            [sm]: {
                                margin: '0 auto',
                                backgroundColor: '#C9D1D6',
                                height: '5px',
                                width: '73px',
                                borderRadius: '3px',
                                marginBottom: '20px',
                                marginTop: '20px',
                            },
                        }}
                    />
                )}
                {title && (
                    <p
                        id={id}
                        css={{
                            padding: `${scale(3)}px ${scale(3)}px ${scale(1)}px`,
                            ...typography('h3'),
                            ...(isCloseButton && { paddingRight: scale(8) + scale(1, true) }),
                            ...(isNewPopup && {
                                padding: '32px 32px 0 32px',
                                fontSize: '28px !important',
                                lineHeight: '33.6px !important',
                            }),
                            ...(isNewStylePopupmobile &&
                                !isZeroStock && {
                                    padding: '32px 32px 0 32px',
                                    fontSize: '28px !important',
                                    lineHeight: '33.6px !important',
                                    [sm]: {
                                        padding: '0px 32px 0 32px',
                                    },
                                }),
                            ...(isZeroStock && {
                                [sm]: {
                                    fontSize: '20px !important',
                                    padding: '0px 32px 0 32px',
                                },
                            }),
                        }}
                    >
                        {title}
                    </p>
                )}
                {children}
            </div>
        </Modal>
    );
};

if (!process.env.IS_STORYBOOK) {
    Modal.setAppElement('#__next');
}

Popup.Body = Body;
Popup.Footer = Footer;

export default Popup;
