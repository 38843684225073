import { Dispatch, SetStateAction, useState } from 'react';

export function useLocalStorage<T>(key: string, initialValue: T | (() => T)): [T, Dispatch<SetStateAction<T>>] {

    const [storedValue, setStoredValue] = useState<T>(() => {
        try {
            const item = window.localStorage.getItem(key);
            if (item) return JSON.parse(item);
            // @ts-ignore
            return typeof initialValue === 'function' ? initialValue() : initialValue;
        } catch (error) {
            // @ts-ignore
            return typeof initialValue === 'function' ? initialValue() : initialValue;
        }
    });

    const setValue = (value: T | ((val: T) => T)) => {
        try {
            const valueToStore = value instanceof Function ? value(storedValue) : value;
            setStoredValue(valueToStore);

            window.localStorage.setItem(
                key,
                typeof valueToStore === 'string' ? valueToStore : JSON.stringify(valueToStore)
            );
        } catch (error) {
        }
    };

    return [storedValue, setValue];
}
