import RobotoBold from '../fonts/Roboto/Roboto-Bold.ttf';
import RobotoMedium from '../fonts/Roboto/Roboto-Medium.ttf';
import RobotoReg from '../fonts/Roboto/Roboto-Regular.ttf';
import SBSansUiBold from '../fonts/SBSansUI/SBSansUI-Bold.ttf';
import SBSansUiCaps from '../fonts/SBSansUI/SBSansUI-Caps.ttf';
import SBSansUiLight from '../fonts/SBSansUI/SBSansUI-Light.ttf';
import SBSansUiRegular from '../fonts/SBSansUI/SBSansUI-Regular.ttf';
import SBSansUiSemiBold from '../fonts/SBSansUI/SBSansUI-Semibold.ttf';

const fontStyles = [
    {
        '@font-face': {
            fontFamily: 'Roboto',
            src: `url(${RobotoReg})`,
            fontDisplay: 'swap',
            fontWeight: 400,
        },
    },
    {
        '@font-face': {
            fontFamily: 'Roboto',
            src: `url(${RobotoMedium})`,
            fontDisplay: 'swap',
            fontWeight: 500,
        },
    },
    {
        '@font-face': {
            fontFamily: 'Roboto',
            src: `url(${RobotoBold})`,
            fontDisplay: 'swap',
            fontWeight: 700,
        },
    },
    {
        '@font-face': {
            fontFamily: 'SB Sans Interface',
            src: `url(${SBSansUiBold})`,
            fontDisplay: 'swap',
            fontWeight: 700,
        },
    },
    {
        '@font-face': {
            fontFamily: 'SB Sans Interface',
            src: `url(${SBSansUiCaps})`,
            fontDisplay: 'swap',
            fontWeight: 400,
        },
    },
    {
        '@font-face': {
            fontFamily: 'SB Sans Interface',
            src: `url(${SBSansUiLight})`,
            fontDisplay: 'swap',
            fontWeight: 300,
        },
    },
    {
        '@font-face': {
            fontFamily: 'SB Sans Interface',
            src: `url(${SBSansUiRegular})`,
            fontDisplay: 'swap',
            fontWeight: 400,
        },
    },
    {
        '@font-face': {
            fontFamily: 'SB Sans Interface',
            src: `url(${SBSansUiSemiBold})`,
            fontDisplay: 'swap',
            fontWeight: 600,
        },
    },
];

export default fontStyles;
