import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { FC } from 'react';
import Media from 'react-media';

import { CurrentUser } from '@api/auth/types';

import { FlatMenuItemExtended } from '@scripts/data/menu';
import { colors, scale, typography, useTheme } from '@scripts/gds';
import { useMedia } from '@scripts/hooks';

import MainHeader from './MainHeader';

const TopHeader = dynamic(() => import('./TopHeader'));

interface HeaderProps {
    breadcrumb?: FlatMenuItemExtended;
    onMenuClick?: () => void;
    user?: CurrentUser;
}

const Header: FC<HeaderProps> = () => {
    const { layout } = useTheme();
    const { pathname, query } = useRouter();
    const { lg } = useMedia();

    const defaultMatches = query?.viewport ? query.viewport === 'desktop' : false;

    return (
        <header
            css={{
                position: 'relative',
                zIndex: 11,
                width: '100%',
                height: scale(23),
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: colors?.white,
                ...typography('bodySm'),
                [lg]: {
                    height: '100%',
                    paddingBottom: pathname === '/' ? scale(3) : 0,
                },
            }}
        >
            <Media query={{ minWidth: layout?.breakpoints.lg || 1280 }} defaultMatches={defaultMatches}>
                {matches => (matches ? <TopHeader /> : null)}
            </Media>

            <MainHeader />
        </header>
    );
};

export default Header;
