import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

import { apiFront } from '@api/index';

export interface CityItem {
    city: string;
    city_guid: string;
    city_kladr: string;
    country_code: string;
    post_index: string;
    region: string;
    region_guid: string;
    geo_lat: string;
    geo_lon: string;
    address_string: string;
    street?: string;
    house?: string;
}

const TIME_EXPIRE = 60 * 60 * 24 * 3;

export const useCityStore = createWithEqualityFn<{
    currentCity: CityItem;
    setCurrentCity: (val: CityItem) => void;
}>(
    set => ({
        currentCity: {
            city: 'Москва',
            city_guid: '',
            city_kladr: '',
            country_code: '',
            post_index: '',
            region: '',
            region_guid: '',
            geo_lat: '',
            geo_lon: '',
            address_string: '',
            street: '',
            house: '',
        },
        setCurrentCity(city: CityItem) {
            set({ currentCity: city });
        },
    }),
    Object.is
);

export const usePrepareCity = () => {
    const { currentCity, setCurrentCity } = useCityStore(
        ({ currentCity, setCurrentCity }) => ({
            currentCity,
            setCurrentCity,
        }),
        shallow
    );
    const [cookies, setCookies] = useCookies(['city']);

    useEffect(() => {
        if (cookies.city) {
            setCurrentCity(cookies.city);
            return;
        }

        navigator.geolocation.getCurrentPosition(
            async position => {
                const { latitude, longitude } = position.coords;

                const data = await apiFront.post('city', { data: { latitude, longitude } });

                if (data.data.suggestions?.length) {
                    const newCity = {
                        city: data.data.suggestions[0].data.city,
                        city_guid: data.data.suggestions[0].data.city_fias_id,
                        city_kladr: data.data.suggestions[0].data.city_kladr_id,
                        country_code: data.data.suggestions[0].data.country_iso_code,
                        post_index: data.data.suggestions[0].data.postal_code,
                        region: data.data.suggestions[0].data.region,
                        region_guid: data.data.suggestions[0].data.region_fias_id,
                        geo_lat: data.data.suggestions[0].data.geo_lat,
                        geo_lon: data.data.suggestions[0].data.geo_lon,
                        address_string: data.data.suggestions[0].value,
                        street: data.data.suggestions[0].data.street,
                        house: data.data.suggestions[0].data.house,
                    };

                    setCurrentCity(newCity);
                    setCookies('city', newCity, { maxAge: TIME_EXPIRE, path: '/' });
                } else {
                    apiFront.post('addresses', { data: currentCity.city }).then(res => {
                        const newCity = {
                            city: res.data[0].data.city,
                            city_guid: res.data[0].data.city_fias_id,
                            city_kladr: res.data[0].data.city_kladr_id,
                            country_code: res.data[0].data.country_iso_code,
                            post_index: res.data[0].data.postal_code,
                            region: res.data[0].data.region,
                            region_guid: res.data[0].data.region_fias_id,
                            geo_lat: res.data[0].data.geo_lat,
                            geo_lon: res.data[0].data.geo_lon,
                            address_string: `${currentCity.city}, ул Никольская, д 8`,
                            street: 'ул Никольская',
                            house: '8',
                        };

                        setCurrentCity(newCity);
                        setCookies('city', newCity, { maxAge: TIME_EXPIRE, path: '/' });
                    });
                }
            },
            () => {
                apiFront.post('addresses', { data: currentCity.city }).then(res => {
                    const newCity = {
                        city: res.data[0].data.city,
                        city_guid: res.data[0].data.city_fias_id,
                        city_kladr: res.data[0].data.city_kladr_id,
                        country_code: res.data[0].data.country_iso_code,
                        post_index: res.data[0].data.postal_code,
                        region: res.data[0].data.region,
                        region_guid: res.data[0].data.region_fias_id,
                        geo_lat: res.data[0].data.geo_lat,
                        geo_lon: res.data[0].data.geo_lon,
                        address_string: `${currentCity.city}, ул Никольская, д 8`,
                        street: 'ул Никольская',
                        house: '8',
                    };

                    setCurrentCity(newCity);
                    setCookies('city', newCity, { maxAge: TIME_EXPIRE, path: '/' });
                });
            }
        );
    }, []);
};
