import { FC, ReactNode, ReactNodeArray, createContext, useContext } from 'react';

import { SeoData } from '@api/seo/types';

const SeoContext = createContext<SeoData | null>(null);
SeoContext.displayName = 'SeoContext';

export const SeoProvider: FC<{ children: ReactNode | ReactNodeArray; seoContextData: SeoData }> = ({
    children,
    seoContextData,
}) => {
    return <SeoContext.Provider value={seoContextData}>{children}</SeoContext.Provider>;
};

export const useSeoContext = () => {
    const context = useContext(SeoContext);

    if (!context) {
        throw new Error(`Hook useSeo must be used within SeoProvider`);
    }

    return context;
};
