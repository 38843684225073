export const phoneFormat = (tel: string) => {
    if (tel.length === 11) {
        if (tel[0] === '7' || tel[0] === '8') {
            return '+7' + tel.substr(1);
        }
    }
    if (tel.length === 10) {
        return '+7' + tel;
    }

    return tel;
};
