import { CSSObject } from '@emotion/core';
import { FormikValues } from 'formik';
import { useEffect, useState } from 'react';
import { useSwipeable } from 'react-swipeable';

import { useVerificationCodeByPhone } from '@api/auth';
import { useRegisterCustomerByCode } from '@api/customers';

import Form from '@controls/Form';

import { CodeInput } from '@components/CodeInput';
import Loader from '@components/controls/Loader';

import { DEFAULT_TIMEZONE } from '@scripts/constants';
import { CustomersTypeEnum } from '@scripts/enums';
import { Button, Layout, colors, scale } from '@scripts/gds';
import { useMedia } from '@scripts/hooks';

export interface SendCodeProps {
    register: (params: {
        phone: string;
        email: string;
        code: string;
        type: string;
        inn?: string;
        ogrn?: string;
        okato?: string;
        okfs?: string;
        okogu?: string;
        okpo?: string;
        oktmo?: string;
        okved?: string;
        org_name?: string;
        org_name_full?: string;
        org_info?: string;
    }) => void;
    logIn: (params: { phone: string; code: string }) => void;
    closeHandler: () => void;
    backToSignin: () => void;
    phone?: string;
    email?: string;
    errorMessage?: string;
    registerInfo?: any;
    setErrorMessage: (arg: string) => void;
    isLogin?: boolean;
    path?: string;
}

const SendCode = ({
    closeHandler,
    logIn,
    backToSignin,
    phone,
    email = '',
    errorMessage,
    registerInfo,
    setErrorMessage,
    isLogin,
    register,
    path,
}: SendCodeProps) => {
    const handlersBox = useSwipeable({
        onSwiped: ({ dir, event }) => {
            event.stopPropagation();

            if (dir === 'Down') {
                closeHandler();
            }
        },
        //@ts-ignore lib
        preventDefaultTouchmoveEvent: true,
    });

    const { ref: documentRef } = useSwipeable({
        //@ts-ignore lib
        preventDefaultTouchmoveEvent: true,
    });
    // attach swipeable to document
    useEffect(() => {
        //@ts-ignore lib
        documentRef(document);
        //@ts-ignore lib
        return () => documentRef({});
    });

    const { xs } = useMedia();

    const errorStyles: CSSObject = {
        background: colors.backgroundRed,
        borderRadius: scale(1, true),
        fontSize: '14px',
        padding: `${scale(1)}px ${scale(2)}px`,
        color: colors.actionRed,
        margin: `8px 0`,

        [xs]: {
            margin: `${scale(2)}px 0 0`,
        },
    };

    const {
        mutateAsync: verificationCodeByPhone,
        isLoading: isLoadingVerificationCodeByPhone,
        error,
    } = useVerificationCodeByPhone();

    const [isLoading, setLoading] = useState(false);
    const [code, setCode] = useState('');
    const [timeLeft, setTimeLeft] = useState(0);
    const [canSendAgain, setCanSendAgain] = useState(false);

    const TIMER_DURATION = 60;

    const startTimer = () => {
        setTimeLeft(TIMER_DURATION);

        const timer = setInterval(() => {
            setTimeLeft(timeLeft => timeLeft - 1);
        }, 1000);

        setTimeout(() => {
            clearInterval(timer);

            setCanSendAgain(true);
        }, 1000 * TIMER_DURATION);
    };

    const formatPhone = (tel?: string) => {
        if (!tel) return null;

        return (
            tel[0] +
            tel[1] +
            ' ' +
            tel[2] +
            tel[3] +
            tel[4] +
            ' ' +
            tel[5] +
            tel[6] +
            tel[7] +
            ' ' +
            tel[8] +
            tel[9] +
            ' ' +
            tel[10] +
            tel[11]
        );
    };

    const {
        mutateAsync: onRegisterCustomer,
        isLoading: isRegisterCustomerLoading,
        error: errorRegisterCustomer,
    } = useRegisterCustomerByCode();

    const loginByPhone = async () => {
        await logIn({ phone: phone || '', code: code });
    };

    const handlerSubmit = async () => {
        setErrorMessage('');
        await verificationCodeByPhone({ phone: phone || '' });
        setCanSendAgain(false);
        setCode('');
        startTimer();
    };

    // reset back errors
    useEffect(() => {
        startTimer();
        return () => {
            setErrorMessage('');
        };
    }, []);

    useEffect(() => {
        if (code && code.length === 4 && isLogin) {
            setLoading(prev => !prev);
            loginByPhone()
                .catch((error: any) => {
                    setErrorMessage(error.message);
                })
                .finally(() => setLoading(false));
        }

        if (code && code.length === 4 && registerInfo && registerInfo.phone && !isLogin) {
            setLoading(prev => !prev);
            register({
                ...(registerInfo.inn && {
                    ...registerInfo.inn,
                    inn: registerInfo.inn.value,
                }),
                type:
                    registerInfo?.inn?.value?.length === 10
                        ? CustomersTypeEnum.ENTITY
                        : registerInfo?.inn?.value?.length === 12
                        ? CustomersTypeEnum.IP
                        : CustomersTypeEnum.PHISICAL,
                code: code,
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone || DEFAULT_TIMEZONE,
                phone: phone,
                email: email,
            });
            setLoading(false);
        }
    }, [code]);

    return (
        <Form onSubmit={handlerSubmit} initialValues={{ code: '' }}>
            {({ submitForm }): FormikValues => (
                <Layout cols={1} gap={0} css={{ alignItems: 'center', textAlign: 'center' }}>
                    {!path && (
                        <div
                            {...handlersBox}
                            css={{
                                [xs]: {
                                    margin: '0 auto',
                                    backgroundColor: '#C9D1D6',
                                    height: '5px',
                                    width: '73px',
                                    borderRadius: '3px',
                                    marginBottom: '20px',
                                },
                            }}
                        />
                    )}
                    <Layout.Item css={{ marginBottom: '24px' }}>
                        <h2
                            css={{
                                fontSize: '28px',
                                fontWeight: 500,
                                lineHeight: '33.6px',
                                textAlign: 'center',
                            }}
                        >
                            Введите код из SMS
                        </h2>
                    </Layout.Item>

                    <Layout.Item css={{ marginBottom: '24px' }}>
                        <div>
                            Мы отправили код на номер{' '}
                            <span css={{ fontWeight: 500, whiteSpace: 'nowrap' }}>{formatPhone(phone)}</span>
                            <span
                                onClick={() => {
                                    backToSignin();
                                    setErrorMessage('');
                                }}
                                css={{
                                    cursor: 'pointer',
                                    fontSize: '14px',
                                    lineHeight: '22.4px',
                                    color: '#2DB4B5',
                                    marginLeft: '12px',
                                    [xs]: {
                                        display: 'block',
                                    },
                                }}
                            >
                                Изменить номер
                            </span>
                        </div>
                    </Layout.Item>
                    <Layout.Item css={{ margin: '0 auto', marginBottom: errorMessage ? '0px' : '24px' }}>
                        {isLoading ? (
                            <Loader css={{ position: 'static', padding: '0' }} width={64} height={64} />
                        ) : (
                            <CodeInput onChange={setCode} />
                        )}
                    </Layout.Item>

                    {errorMessage && (
                        <div css={{ ...errorStyles, marginBottom: '24px' }}>
                            {errorMessage || errorRegisterCustomer?.message}
                        </div>
                    )}

                    {!canSendAgain && (
                        <Layout.Item
                            css={{
                                height: '48px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginBottom: '44px',
                            }}
                        >
                            <div>Если код не придёт, можно получить новый через {timeLeft} сек.</div>
                        </Layout.Item>
                    )}

                    {!canSendAgain && false && (
                        <Layout.Item
                            css={{
                                height: '48px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginBottom: '44px',
                            }}
                        >
                            <div>Вы превысили число попыток отправки кода. Попробуйте через 30 мин.</div>
                        </Layout.Item>
                    )}

                    {canSendAgain && (
                        <Layout.Item css={{ marginBottom: errorMessage ? '0px' : '44px' }}>
                            <Button
                                type="submit"
                                block
                                theme="secondary"
                                size="md"
                                data-testid="button-submit"
                                role="button"
                            >
                                Получить код
                            </Button>
                        </Layout.Item>
                    )}
                </Layout>
            )}
        </Form>
    );
};

export default SendCode;
